<template>
  <div>
    <h3 class="text-trail-title1 text-trail-text-title-normal">
      {{ t('homePageTitle') }}
    </h3>

    <div
      v-if="permissionsStore.canBuyEsim && !authStore?.isOnlyAPIPartnerStream"
      class="mt-6"
      data-testid="homepage-banner"
    >
      <Banner />
    </div>

    <div
      class="grid grid-cols-[repeat(auto-fill,_minmax(19rem,_1fr))] gap-6 mt-6"
    >
      <Card
        v-for="(card, index) in permittedCards"
        :key="index"
        :title="t(card.title)"
        :description="t(card.description)"
        :isNew="card.isNew"
        :icon="card.icon"
        :actions="card.actions"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePermissionsStore } from '@/stores/permissions'
import { homeCardData } from '@/constant/homeData'
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { definePage } from 'vue-router/auto'
import type { ComputedRef } from 'vue'
import type {
  HomeCardType,
  HomeCardsStreamsAndPermissions,
} from '@/constant/homeData'
import { hasPermissions } from '@/utils/permissions'
import { hasStreams } from '@/utils/streams'

definePage({
  name: 'home',
  meta: {
    requiresAuth: true,
  },
})

const { t } = useI18n({ useScope: 'global' })
const permissionsStore = usePermissionsStore()
const authStore = useAuthStore()

const checkPermissions = ({
  requiredPermissions,
  excludePermissions,
}: HomeCardsStreamsAndPermissions) => {
  const hasExcludedPerms = excludePermissions
    ? hasPermissions(excludePermissions)
    : false
  const hasRequiredPerms = hasPermissions(requiredPermissions || [])

  if (hasExcludedPerms) {
    return false
  }

  return hasRequiredPerms
}

const checkStreams = ({ targetStreams }: HomeCardsStreamsAndPermissions) => {
  return hasStreams(targetStreams, { strict: true })
}

const permittedCards: ComputedRef<HomeCardType[]> = computed(() => {
  return homeCardData
    .filter(checkStreams)
    .filter(checkPermissions)
    .map((card: HomeCardType) => {
      const actions = card.actions?.filter(checkPermissions)

      return {
        ...card,
        actions,
      }
    })
})
</script>
