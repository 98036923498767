<template>
  <Icon
    :icon="icon"
    :height="iconSize"
    :width="iconSize"
    :color="iconColor"
    data-testid="trailIcon"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Icon } from '@iconify/vue'
import { SEMANTIC_COLORS } from '@/tailwind-config/colors'

export type IconColor = keyof (typeof SEMANTIC_COLORS)['icon']
export type TrailIconProps = {
  icon: string
  size?: 'small' | 'normal' | number
  color?: IconColor
}

const props = withDefaults(defineProps<TrailIconProps>(), {
  size: 'normal',
})

const iconSize = computed(() => {
  if (props.size === 'small') {
    return 16
  }

  if (props.size === 'normal') {
    return 24
  }

  return props.size
})

const iconColor = computed(() => {
  return props.color ? SEMANTIC_COLORS.icon[props.color] : undefined
})
</script>
