<template>
  <div>
    <div class="grid gap-y-6 py-4 pr-8">
      <div
        class="grid grid-flow-col justify-start items-center ml-2"
        @click="() => router.push('/api-orders')"
      >
        <BaseIcon icon="local:arrow-left" :size="16" />

        <p class="ltr:ml-[6px] rtl:mr-[6px] text-grey-500">
          {{ $t('orderDetailBacktoOrders') }}
        </p>
      </div>

      <h2>Order detail</h2>

      <OrderDetails :order-id="orderId" :is-api="true" />
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router/auto'
import { definePage } from 'vue-router/auto'
import { computed } from 'vue'

definePage({
  name: 'api-order-detail',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Order'],
  },
})

const router = useRouter()
const route = useRoute()

const orderId = computed(() => {
  const regex = /-0*([1-9]\d*)$/
  return route.params.id?.match(regex)?.[1]
})
</script>
