<template>
  <AuthPagesLayout data-testid="sign-in-page">
    <h3
      class="text-trail-display2 text-trail-text-display-normal"
      data-testid="sign-in-subtitle"
    >
      {{ $t('scenes.login.subtitle') }}
    </h3>

    <SignInForm />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'

definePage({
  meta: { requiresAuth: false },
  name: 'sign-in',
})
</script>
