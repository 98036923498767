import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { TrailToastProps } from '@/components/Trail/TrailToast.vue'

type TrailToastPropsWithId = TrailToastProps & { id: number }

export const useToastsStore = defineStore('toasts', () => {
  const nextToastId = 0
  const toasts = ref<TrailToastPropsWithId[]>([])

  const removeToast = async (id: number) => {
    const toastIndex = toasts.value.findIndex((toast) => toast.id === id)
    toasts.value[toastIndex].open = false

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        toasts.value.splice(toastIndex, 1)
        resolve()
      }, 400)
    })
  }

  return {
    nextToastId,
    toasts,
    removeToast,
  }
})
