<template>
  <button
    :class="`
      ${computedBackgroundColor}
      ${computedTextColor}
      ${computedHoverBackgroundColor}
      ${computedActiveBorderColor}
      ${computedBorderColor}
      ${computedCursor}
      border
      grid
      grid-flow-col
      justify-center 
      items-center
      gap-2
      rounded-lg
      py-2
      ${horizontalPaddingClass}
      h-11
    `"
    :disabled="disabled || isLoading"
    @click="handleClick"
  >
    <template v-if="isLoading">
      <BaseSpinner :size="6" />
    </template>

    <template v-else>
      <slot name="prepend">
        <BaseIcon v-if="iconifyPrepend" :icon="iconifyPrepend" :size="24" />
      </slot>

      <span class="text-3.5 leading-4 font-medium">
        <slot>
          <BaseIcon v-if="icon" :icon="icon" :size="24" />
        </slot>
      </span>

      <slot name="append">
        <BaseIcon v-if="iconifyAppend" :icon="iconifyAppend" :size="24" />
      </slot>
    </template>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type ButtonType = 'primary' | 'secondary' | 'cta' | 'tertiary'

export interface ButtonProps {
  iconifyPrepend?: string
  iconifyAppend?: string
  icon?: string
  isLoading?: boolean
  disabled?: boolean
  type?: ButtonType
}

const props = withDefaults(defineProps<ButtonProps>(), {
  iconifyPrepend: '',
  iconifyAppend: '',
  isLoading: false,
  disabled: false,
  type: 'primary',
})

const emits = defineEmits<{
  click: [void]
}>()

const computedBackgroundColor = computed(() => {
  if (props.disabled) {
    if (props.type === 'secondary') {
      return 'bg-transparent'
    } else {
      return 'bg-grey-50'
    }
  } else if (props.type === 'primary') {
    return 'bg-grey-800'
  } else if (props.type === 'secondary') {
    return 'bg-transparent'
  } else if (props.type === 'tertiary') {
    return 'bg-transparent'
  } else if (props.type === 'cta') {
    return 'bg-ultra-marine-blue-600'
  } else {
    return 'bg-grey-800'
  }
})

const computedTextColor = computed(() => {
  if (props.type === 'secondary') {
    return props.disabled ? 'text-grey-100' : 'text-grey-800'
  } else if (props.type === 'tertiary') {
    return 'text-trail-gray-900'
  } else {
    return 'text-seashell-100'
  }
})

const computedHoverBackgroundColor = computed(() => {
  if (props.disabled) {
    if (props.type === 'secondary') {
      return 'hover:bg-transparent'
    } else {
      return 'hover:bg-grey-50'
    }
  } else if (props.type === 'primary') {
    return 'hover:bg-grey-700'
  } else if (props.type === 'secondary') {
    return 'hover:bg-seashell-600'
  } else if (props.type === 'tertiary') {
    return 'hover:bg-trail-gray-50'
  } else if (props.type === 'cta') {
    return 'hover:bg-ultra-marine-blue-500'
  } else {
    return 'hover:bg-grey-700'
  }
})

const computedActiveBorderColor = computed(() => {
  return 'active:border-seashell-900'
})

const computedBorderColor = computed(() => {
  if (props.type === 'secondary') {
    return 'border-seashell-900'
  } else {
    return 'border-transparent'
  }
})

const computedCursor = computed(() => {
  return props.disabled || props.isLoading
    ? 'cursor-not-allowed'
    : 'cursor-pointer'
})

const horizontalPaddingClass = computed(() => {
  if (props.icon) {
    return 'px-2'
  } else {
    return 'px-4'
  }
})

const handleClick = () => {
  if (!props.disabled || !props.isLoading) {
    emits('click')
  }
}
</script>
