import { resetAxiosAuthToken } from './axios'
import { useAuthStore } from '@/stores/auth'
import { usePermissionsStore } from '@/stores/permissions'
import { useFeatureFlagsStore } from '@/stores/feature-flags'
import { axios } from '@/http'

export const setupPermissions = (store) => {
  const permissionsStore = usePermissionsStore()
  permissionsStore.setPermissions(store.user)
}

export const setToken = (token) => {
  window.localStorage.setItem('token', token)
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

export async function login(email, password) {
  const authStore = useAuthStore()
  const featureFlagsStore = useFeatureFlagsStore()

  try {
    resetAxiosAuthToken()
    const { data } = await axios.post('/auth/v1/login', { email, password })
    const token = data.token
    setToken(token)
    await featureFlagsStore.initFeatureFlags()
    authStore.setUser(data.user)
    setupPermissions(authStore)
  } catch (err) {
    console.error(err)
    return Promise.reject(err)
  }
}

export async function logout() {
  try {
    await axios.post('/auth/v1/logout')

    window.localStorage.removeItem('token')
  } finally {
    resetAxiosAuthToken()
  }
}

export async function refreshToken() {
  await axios.post('/auth/v1/refresh')
}

export function removeAppLoading() {
  const authStore = useAuthStore()

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  setupPermissions(authStore)
}

// TODO: false positive here, TS could solve Sonarcloud issue
export function getAxiosAccessToken() {
  return axios.defaults.headers.common['Authorization']
}

export function getStorageAccessToken() {
  return window.localStorage.getItem('token')
}
