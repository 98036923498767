<template>
  <RadixToastProvider>
    <TrailToast
      v-for="toast in toastsStore.toasts"
      :key="toast.id"
      v-bind="toast"
      data-testid="trailToast"
      @update:open="handleCloseToast(toast.id)"
    />

    <RadixToastViewport
      v-show="toastsStore.toasts.length"
      ref="viewport"
      class="fixed top-0 right-0 flex flex-col pt-8 pr-8 gap-3 w-96 list-none z-9999 pointer-events-auto"
    />
  </RadixToastProvider>
</template>

<script setup lang="ts">
import { type ComponentPublicInstance, ref } from 'vue'
import { useToastsStore } from '@/stores/toasts'

const toastsStore = useToastsStore()
const viewport = ref<ComponentPublicInstance>()

const handleCloseToast = (id: number) => {
  toastsStore.removeToast(id)
  viewport.value?.$el?.blur()
}
</script>
