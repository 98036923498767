<template>
  <div
    class="rounded-lg pl-3 pt-2 pb-2 pr-2 bg-trail-gray-50 shadow-primary font-sans"
    data-testid="baseToast"
  >
    <div class="flex items-center gap-2">
      <BaseIcon
        :size="24"
        :icon="toastIcon"
        :class="`p-2 rounded-2xl ${toastColors}`"
        data-testid="baseToastIcon"
      />

      <p
        class="text-trail-gray-700 text-3.5 leading-6"
        data-testid="baseToastText"
      >
        {{ content }}
      </p>

      <BaseIcon
        :size="16"
        icon="heroicons:x-mark"
        class="text-gray-900 self-start ml-auto cursor-pointer"
        data-testid="baseToastCloseButton"
        @click="handleCloseToast"
      />
    </div>

    <NewButton
      v-if="buttonText"
      type="tertiary"
      class="mt-3"
      data-testid="baseToastActionButton"
      @click="handleClickButton"
    >
      {{ buttonText }}
    </NewButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type BaseToastProps = {
  type: 'info' | 'success' | 'warning' | 'error'
  content: string
  buttonText?: string
  onClick?: () => void
}

const props = withDefaults(defineProps<BaseToastProps>(), {
  type: 'info',
})

const emit = defineEmits<{
  'close-toast': []
  click: []
}>()

const toastIcon = computed(
  () =>
    ({
      info: 'heroicons:information-circle',
      success: 'heroicons:check-circle',
      warning: 'heroicons:exclaimation-circle',
      error: 'heroicons:x-circle',
    })[props.type]
)

const toastColors = computed(
  () =>
    ({
      info: 'bg-trail-gray-100 text-trail-gray-900',
      success: 'bg-trail-green-100 text-trail-green-700',
      warning: 'bg-trail-yellow-100 text-trail-orange-700',
      error: 'bg-trail-red-100 text-trail-red-700',
    })[props.type]
)

const handleCloseToast = () => {
  emit('close-toast')
}

const handleClickButton = () => {
  props.onClick?.()
  handleCloseToast()
}
</script>
