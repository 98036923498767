<template>
  <div class="grid gap-y-6">
    <h3 class="text-grey-800">{{ $t('apiIntegrationPageTitle') }}</h3>

    <div class="flex flex-wrap gap-4">
      <TrailCard
        class="flex flex-col gap-4 flex-wrap lg:w-fit md:w-fit w-full lg:basis-3/5 md:basis-4/5 basis-5/5"
        background-color="bg-trail-surface-primary-on_dark border-0"
      >
        <TrailBanner
          data-testid="apiIntegrationBanner"
          class="p-4"
          type="warning"
        >
          <p class="flex gap-4 items-center">
            <TrailIcon icon="heroicons-outline:exclamation-circle" />
            {{ $t('apiIntegrationWarning') }}
          </p>
        </TrailBanner>

        <div class="grid gap-3">
          <p class="text-trail-body3 text-trail-text-body-normal">
            {{ $t('apiIntegrationApiClientId') }}
          </p>

          <TrailInputField
            v-model="clientId"
            data-testid="apiIntegrationInputClientId"
            :placeholder="$t('loading')"
            class="truncate"
            icon="heroicons-outline:clipboard-document"
            inputDisabled
            @click="copyClientId"
          />
        </div>

        <div class="grid gap-3">
          <p class="text-trail-body3 text-trail-text-body-normal">
            {{ $t('apiIntegrationApiClientSecret') }}
          </p>

          <TrailInputField
            v-model="clientSecret"
            data-testid="apiIntegrationInputClientSecret"
            :placeholder="$t('loading')"
            class="truncate"
            icon="heroicons-outline:clipboard-document"
            inputDisabled
            @click="copyClientSecret"
          />
        </div>
      </TrailCard>

      <TrailCard
        class="flex basis-1/5 flex-col gap-4 self-start"
        background-color="bg-trail-surface-primary-on_dark border-0 !w-fit"
      >
        <p class="text-trail-title4 text-trail-text-title-normal">
          {{ $t('apiIntegrationApiDocumentation') }}
        </p>

        <p
          class="text-trail-body3 text-trail-text-body-normal text-wrap lg:w-64 md:w-64"
        >
          {{ $t('apiIntegrationDocDescription') }}
        </p>

        <a
          href="https://partners-doc.airalo.com/"
          target="_blank"
          rel="noopener"
        >
          <TrailButton class="w-full">
            {{ $t('apiIntegrationViewDocumentation') }}
          </TrailButton>
        </a>
      </TrailCard>
    </div>

    <AddBillingDetailsOverlay
      v-if="!hasBillingDetails"
      v-model:is-open="addBillingDetailsIsOpen"
      @close="$router.go(-1)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrailToast } from '@/composables/Trail/useTrailToast.ts'
import { definePage } from 'vue-router/auto'
import { getApiCredentials } from '@/api/auth'
import { useBillingDetails } from '@/composables/billing/use-billing-details'
import type { ErrorData } from '@/api/types/useApiDataError.ts'

definePage({
  name: 'api-integration',
  meta: { requiresAuth: true, requiredPermissions: ['show-api-credentials'] },
})

defineEmits(['close'])

const { t } = useI18n({ useScope: 'global' })
const toast = useTrailToast()
const { hasBillingDetails } = useBillingDetails()
const addBillingDetailsIsOpen = ref<boolean>(true)
const clientId = ref('')
const clientSecret = ref('')
const ultimateChatInstance = ref<Window['UltimateChat'] | null>(null)

const initUltimateChat = () => {
  if (window.UltimateChat) {
    ultimateChatInstance.value = window.UltimateChat.Initialize({
      botId: import.meta.env.VITE_ULTIMATE_CHAT_BOT_ID,
      navbar: {
        avatarUrl:
          'https://res.cloudinary.com/postman/image/upload/v1582796810/team/baby43ytba7ahxmqkwm7.ico',
        title: 'Airalo Assistant',
      },
      recoverConversation: true,
      messageGeneratedAdditionalInfo: false,
      showLlmSources: true,
      showAIAnswerLabel: true,
      allowSoundNotifications: true,
      chatPosition: 'bottom-right',
      openOnLoad: false,
    })
  }
}

onMounted(async () => {
  initUltimateChat()
  try {
    const { client_id, client_secret } = await getApiCredentials()

    clientId.value = client_id
    clientSecret.value = client_secret
  } catch (error) {
    const errors = error as ErrorData
    const technical_user = errors?.response?.data?.errors?.technical_user
    if (technical_user) {
      let displayMsg = ''
      let errorMsg = technical_user?.[0]

      if (errorMsg === 'not_found') {
        displayMsg = t('apiIntegrationTechNotFound')
      }
      if (errorMsg === 'multiple_found') {
        displayMsg = t('apiIntegrationTechMultipleFound')
      }

      toast.negative({ content: displayMsg, timeout: 8000 })
    }
  }
})

onUnmounted(() => {
  if (ultimateChatInstance.value) {
    ultimateChatInstance.value.destroy()
  }
})

const copyClientId = () => {
  try {
    navigator.clipboard.writeText(clientId.value)

    toast.positive({
      content: t('apiIntegrationClientIdCopied'),
      timeout: 1500,
    })
  } catch (error) {
    console.error(error)

    toast.negative({
      content: t('apiIntegrationCopyError'),
      timeout: 1500,
    })
  }
}

const copyClientSecret = () => {
  try {
    navigator.clipboard.writeText(clientSecret.value)

    toast.positive({
      content: t('apiIntegrationClientSecretCopied'),
      timeout: 1500,
    })
  } catch (error) {
    console.error(error)

    toast.negative({
      content: t('apiIntegrationCopyError'),
      timeout: 1500,
    })
  }
}
</script>
