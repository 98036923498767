<template>
  <AuthPagesLayout>
    <SignInSSOForm />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
import { ACCESS_TOKEN_QUERY_PARAM_NAME } from '@/api/models/sso'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import { useAuthStore } from '@/stores/auth'
import { setToken } from '@/utils/auth'
import { useI18n } from 'vue-i18n'
import { definePage, useRoute, useRouter } from 'vue-router/auto'

const route = useRoute()
const router = useRouter()
const toast = useTrailToast()
const authStore = useAuthStore()
const { t } = useI18n({ useScope: 'global' })

if (route.query[ACCESS_TOKEN_QUERY_PARAM_NAME]) {
  setToken(route.query[ACCESS_TOKEN_QUERY_PARAM_NAME])
  authStore.getAndSetUser().then(() => {
    toast.positive({
      content: t('messages.success.login-successfully'),
      timeout: 2000,
    })
    router.push('/home')
  })
}

definePage({
  meta: { requiresAuth: false },
  name: 'sign-in-sso',
})
</script>
