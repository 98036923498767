const isPullRequestEnvironment = () => {
  const { hostname } = window.location
  return (
    ['epic', 'feat', 'fix', 'refactor'].some((prefix) =>
      hostname.startsWith(prefix)
    ) && hostname.endsWith('platform.eks.partners.airalo.com')
  )
}

const isStaging = () => {
  return (
    window.location.hostname === 'staging.partners.airalo.com' ||
    window.location.hostname === 'localhost'
  )
}

const isSandBox = () => {
  return window.location.hostname === 'sandbox.partners.airalo.com'
}

const isProduction = () => {
  return window.location.hostname === 'partners.airalo.com'
}

export { isPullRequestEnvironment, isStaging, isSandBox, isProduction }
