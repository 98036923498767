import { useAuthStore } from '@/stores/auth'
import { resetAxiosAuthToken } from '@/utils/axios'
import { removeAppLoading } from '@/utils/auth'
import { useToast } from '@/composables/useToast'
import { default as baseAxios } from 'axios'

const toast = useToast()

const axios = baseAxios.create()

axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

const storageToken = window.localStorage?.token

if (storageToken) {
  axios.defaults.headers.common.Authorization = `Bearer ${storageToken}`
}

let refreshStatus = false
let refreshStatusPromiseResolve
let stuckedRequestsCount = 0

axios.interceptors.response.use(
  (response) => response,
  async function (error) {
    stuckedRequestsCount++
    const store = useAuthStore()
    const originalRequest = error.config
    const responseURL = error.request.responseURL

    if (!responseURL.includes('/refresh')) {
      await refreshStatus
    }
    if (
      refreshStatus === true &&
      !originalRequest._retry &&
      error?.response?.status === 401
    ) {
      originalRequest._retry = true

      originalRequest.headers.Authorization =
        axios.defaults.headers.common['Authorization']

      stuckedRequestsCount--

      if (stuckedRequestsCount < 1) {
        stuckedRequestsCount = 0
        refreshStatus = false
      }

      return axios(originalRequest)
    }

    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      !responseURL.includes('/refresh') &&
      !refreshStatus &&
      error?.response?.data?.message !== 'SSO_ENFORCED'
    ) {
      let token = ''
      stuckedRequestsCount--
      refreshStatus = new Promise((resolve) => {
        refreshStatusPromiseResolve = resolve
      })

      try {
        const { data } = await axios.post('/auth/v1/refresh')

        token = data.token
      } catch (error) {
        resetAxiosAuthToken()

        await store.logout()

        refreshStatusPromiseResolve()
        refreshStatus = false

        window.localStorage.removeItem('token')

        removeAppLoading()

        return Promise.reject(error)
      }

      originalRequest._retry = true

      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        originalRequest.headers.Authorization = `Bearer ${token}`
        window.localStorage.setItem('token', token)

        refreshStatusPromiseResolve()
        refreshStatus = true

        return axios(originalRequest)
      }
    }

    if (refreshStatusPromiseResolve) {
      refreshStatusPromiseResolve()
    }

    stuckedRequestsCount--

    if (error?.response?.status === 403) {
      toast.error(
        `Sorry, it seems you don’t have the necessary permissions to access this feature. Please contact your administrator or our support team for assistance.`,
        {
          timeout: 8000,
          bodyClassName: 'errorToastTestClass',
          icon: true,
          maxToasts: 1,
          newestOnTop: true,
        }
      )
    }

    return Promise.reject(error)
  }
)

export { axios }

export default (app) => {
  app.axios = axios
  app.$http = axios

  app.config.globalProperties.axios = axios
  app.config.globalProperties.$http = axios
}
