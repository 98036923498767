<template>
  <div class="mt-6">
    <Invoice />
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'
definePage({
  name: 'billing-details',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.invoice.show'],
  },
})
</script>
