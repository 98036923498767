<template>
  <div>
    <h3>{{ $t('billingPageTitle') }}</h3>

    <BillingTable />
  </div>
</template>

<script setup lang="ts">
import { definePage } from 'vue-router/auto'

definePage({
  name: 'billing',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.invoice.list'],
  },
})
</script>
