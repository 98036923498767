const setupConfiguration = () => {
  // check skin  for localstorage
  if (localStorage.skin === 'bordered') {
    document.body.classList.add('skin--bordered')
  } else {
    document.body.classList.add('skin--default')
  }
  // check direction for localstorage
  if (localStorage.direction === 'true') {
    document.documentElement.setAttribute('dir', 'rtl')
  } else {
    document.documentElement.setAttribute('dir', 'ltr')
  }
}

export default setupConfiguration
