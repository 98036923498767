<template>
  <div
    class="max-w-500 my-12 md:my-18 lg:my-24 px-2 lg:w-1/3"
    data-testid="influencer-stepper-page"
  >
    <InfluencerStepper
      :influencer-application="leadApplication"
      data-testid="influencer-stepper"
    />
  </div>
</template>

<script lang="ts" setup>
import { useOnboarding } from '@/composables/onboarding/use-influencers-onboarding'
import { definePage } from 'vue-router/auto'

definePage({
  name: 'onboarding-influencer',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding/paid-influencers',
})

const { findLeadApplicationByRouteSlug } = useOnboarding()

const leadApplication = await findLeadApplicationByRouteSlug()
</script>
