<template>
  <div>
    <div class="flex flex-col gap-y-6">
      <div class="flex gap-0 md:gap-8 justify-between">
        <div class="flex flex-col gap-8 grow">
          <h3 class="text-trail-title1 text-trail-text-title-normal">
            {{ $t(buyEsimStore?.shoppingBagOptions?.headerTitle) }}
          </h3>

          <div
            v-if="isModalTopup"
            class="grid grid-flow-col items-center gap-x-2"
          >
            <div>
              <h5 class="text-trail-title3 text-trail-text-title-normal">
                {{ $t('buyEsimChooseTopupPackageTitle') }}
              </h5>

              <p class="text-trail-body2 text-trail-text-body-normal mt-2">
                {{ $t('buyEsimChoosePackageDescription') }}
              </p>
            </div>
          </div>

          <TrailSearchField
            v-else
            v-model:search-query="searchQuery"
            search-placeholder="placeholders.search.bar.store"
          />
        </div>

        <ShoppingCart
          :shopping-cart="buyEsimStore?.shoppingCart || []"
          class="ml-auto"
          @update:quantity="changePackagesAmount"
        />
      </div>

      <RecentDestinations
        v-if="!isModalTopup"
        @update:region="selectedRegion = $event"
        @update:search-query="searchQuery = $event"
      />

      <PackageTable
        v-model:selected-region="selectedRegion"
        :topup-options="topupOptions"
        :topup-loading="isTopupsLoading"
        :search-query="searchQuery"
        @open-package-detail="openPackageDetail"
      />
    </div>

    <PackageDetailsModal
      :is-opened="showPackageDetail"
      :sim="packageDetailData"
      :available-packages="
        packageDetailData?.packages?.filter((item) => item.type === 'topup') ||
        []
      "
      :global-networks="globalNetworks"
      @clickClose="closePackageDetail()"
    />
  </div>
</template>

<script setup>
import { getTopupOptions } from '@/api/manageEsims'
import { useBuyEsimStore } from '@/stores/buy-esim.store'
import { generateNetworks } from '@/utils/generateNetworks'
import { useMediaQuery } from '@vueuse/core'
import { onMounted, computed, ref, watchEffect } from 'vue'
import { definePage, onBeforeRouteLeave } from 'vue-router/auto'

definePage({
  name: 'esim-store',
  meta: { requiresAuth: true, requiredPermissions: ['buy-esims'] },
})

// Definitions
const topupOptions = ref([])
const isTopupsLoading = ref(false)
const chosenDestination = ref(null)
const showPackageDetail = ref(false)
const mobileCartState = ref('total') // 'total' || 'bag'
const packageDetailData = ref(null)
const globalNetworks = ref(null)
const buyEsimStore = useBuyEsimStore()
const isLargeScreen = useMediaQuery('(min-width: 640px)')

// Card Data
const lastFourDigits = ref('')
const expiryMonth = ref('')
const expiryYear = ref('')
const cardBrand = ref('')
const cardId = ref('')

const selectedRegion = ref('countries')
const searchQuery = ref('')

// Computed
const isModalTopup = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.isTopup
})

// Operators Packages
const openPackageDetail = (item) => {
  if (!isModalTopup.value) {
    showPackageDetail.value = true
    packageDetailData.value = item
    generateNetworks(packageDetailData.value, globalNetworks)
  }
}

// Cart
const closePackageDetail = () => {
  showPackageDetail.value = false
}

const removeFromShoppingBag = (packageName) => {
  if (isModalTopup.value) {
    buyEsimStore.topupShoppingBag = buyEsimStore.topupShoppingBag.filter(
      (shoppingItem) => shoppingItem.slug !== packageName
    )
  } else {
    buyEsimStore.removePackage(packageName)
  }
}

const changePackagesAmount = (packageName, value) => {
  if (value === 0) {
    removeFromShoppingBag(packageName)
  } else if (isModalTopup.value) {
    const shoppingItem = buyEsimStore.topupShoppingBag.find(
      (bag) => bag.slug === packageName
    )
    if (shoppingItem) {
      shoppingItem.quantity = value
    }
  } else {
    buyEsimStore.changePackagesAmount(packageName, value)
  }
}

// Payments
const clearCardDetails = () => {
  lastFourDigits.value = ''
  cardBrand.value = ''
  expiryMonth.value = ''
  expiryYear.value = ''
  cardId.value = ''
}

const clearState = () => {
  chosenDestination.value = null
  showPackageDetail.value = false
  packageDetailData.value = null
  clearCardDetails()
}

onMounted(async () => {
  if (isModalTopup.value) {
    isTopupsLoading.value = true

    const response = await getTopupOptions(
      buyEsimStore.shoppingBagOptions.topupEsimId
    )

    topupOptions.value = response?.data

    isTopupsLoading.value = false
  } else {
    await buyEsimStore.getDestinations()
  }
})

watchEffect(() => {
  if (isLargeScreen.value === true) {
    mobileCartState.value = 'total'
  }
})

onBeforeRouteLeave((to) => {
  if (to.name !== 'esim-store-checkout') {
    buyEsimStore.topupShoppingBag = []
    buyEsimStore.resetShoppingBagOptions()
    clearState()
  }
})
</script>
