import { POSITION, type PluginOptions } from 'vue-toastification'

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  closeButton: false,
  closeOnClick: false,
  transition: 'Vue-Toastification__fade',
  containerClassName: 'partner-toast-container',
  toastClassName: 'partner-toast',
  icon: '',
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false
    }
    return toast
  },
}

export default options
