import { useToast as useToastification } from 'vue-toastification'
import BaseToast, { type BaseToastProps } from '@/components/BaseToast.vue'

type ToastOptions = Parameters<ReturnType<typeof useToastification>>[1]
type ToastContent = string | Omit<BaseToastProps, 'type'>

export function useToast() {
  const toastification = useToastification()

  const toast = (
    type: BaseToastProps['type'],
    content: ToastContent,
    options?: ToastOptions
  ) => {
    const toastContent = typeof content === 'string' ? { content } : content

    toastification(
      {
        component: BaseToast,
        props: {
          type,
          ...toastContent,
        },
      },
      options
    )
  }

  const success = (content: ToastContent, options?: ToastOptions) => {
    toast('success', content, options)
  }

  const info = (content: ToastContent, options?: ToastOptions) => {
    toast('info', content, options)
  }

  const error = (content: ToastContent, options?: ToastOptions) => {
    toast('error', content, options)
  }

  const warning = (content: ToastContent, options?: ToastOptions) => {
    toast('warning', content, options)
  }

  return {
    success,
    info,
    error,
    warning,
  }
}

export { POSITION } from 'vue-toastification'
