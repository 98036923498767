import { createI18n } from 'vue-i18n'
import messages from '@/translation/translations.json'
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

// Fetch localstorage locale or sets default
const langStorage = localStorage.getItem('lang')
const locale = langStorage || import.meta.env.VITE_I18N_LOCALE || 'en'

export default createI18n({
  globalInjection: true,
  legacy: false,
  locale,
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || 'en',
  messages,
})
