import { usePermissionsStore } from '@/stores/permissions'

/**
 * This function checks if the user has all the required permissions.
 *
 * @param requiredPermissions an array of required permissions
 * @returns true if the user has all the required permissions, false otherwise
 */
export const hasPermissions = (requiredPermissions: string[] = []) => {
  const permissionsStore = usePermissionsStore()
  const userPermissions: string[] = permissionsStore.getAllPermissions

  return requiredPermissions.every((permission) => {
    return userPermissions.includes(permission)
  })
}
