<template>
  <BrandForm
    :loading="creatingNewBrandSettings"
    :backend-errors="backendValidationErrors"
    @submit="handleSubmitForm"
  >
    <template #header>
      <h3 class="text-trail-title1 text-trail-text-title-normal mb-6">
        {{ $t('components.nav.menu.new-brand') }}
      </h3>
    </template>
  </BrandForm>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { useI18n } from 'vue-i18n'
import { useApiData } from '@/composables/useApiData'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import type { NewBrandSettingsResponse } from '@/models/brandSettingsModels'
import { mapBackendValidationError } from '@/utils/brandSettings'

definePage({
  name: 'new-brand',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['add-Modules\\Company\\Models\\CompanyBrand'],
  },
})

const {
  data: newBrandSettings,
  createData: createNewBrandSettings,
  loading: creatingNewBrandSettings,
  error: newBrandSettingsError,
} = useApiData<NewBrandSettingsResponse>('/company/v1/company-brands', {
  immediate: false,
  removeEmptyValuesFromRequest: false,
  config: {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
})

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { positive, negative } = useTrailToast()

const createdBrandRedirect = () => {
  const brandId = newBrandSettings.value!.id
  const { redirectToShareBulkEsims, redirectToShareEsim } = route.query || {}

  if (redirectToShareBulkEsims || redirectToShareEsim) {
    router.replace({
      path: '/platform/manage-esims',
      query: {
        redirectToShareBulkEsims,
        redirectToShareEsim,
        brand_id: brandId,
      },
    })
  } else {
    router.replace(
      `/company-settings/brand-settings/${newBrandSettings.value!.id}`
    )
  }
}

const backendValidationErrors = ref<Record<string, string>>()
const handleSubmitForm = async (formData: FormData) => {
  backendValidationErrors.value = undefined

  await createNewBrandSettings(formData)

  if (newBrandSettings.value) {
    positive({
      content: t('brandFormSuccessCreateToast'),
    })
    createdBrandRedirect()
  } else if (newBrandSettingsError.value) {
    backendValidationErrors.value = mapBackendValidationError(
      newBrandSettingsError.value
    )
    if (!backendValidationErrors.value) {
      negative({
        content: t('errorTitle'),
      })
    }
  }
}
</script>
